.about {
    background-color: #DC96BA;
    padding: 50px;
    text-align: center;
}

.about h2 {
    text-decoration-color: #FDDAE9;
}

.about p {
    width: 70%;
    margin: 10px auto;
}

@media only screen and (max-width: 600px) {
    .about p {
        width: 100%;
        margin: auto;
    }
}