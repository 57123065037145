.home {
    background-color: #49276B;
    background-image: url('../../assets/images/background.jpg');
    background-repeat: repeat;
    background-size: 100% 100%;
    background-position: center;
    height: 100vh;
    color: #FFFFFF;
}

.content {
    position: absolute;
    top: 30%;
    left: 20%;
    text-align: center;
}

.content h1 {
    font-size: 80px;
    font-weight: initial;
    margin-bottom: 10px;
}

.content ul {
    padding: 0;
}

.content ul li {
    display: inline-block;
    text-decoration: none;
    margin: 10px 20px;
}

.content ul li a{
    color: #FFFFFF;
    text-decoration: none;
}

.content ul li a:hover{
    color: #EEE;
    text-decoration: none;
}

@media only screen and (max-width: 600px) {
    .content h1 {
        font-size: 50px;
    }

    .content ul li {
        margin: 10px 7px;
    }
}