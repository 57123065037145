.skills {
    background-color: #DC96BA;
    padding: 50px;
}

.skills section .title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.skills .items {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    justify-content: center;
}

.skills .item {
    padding: 10px 20px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

.skills .item img {
    max-width: 120px;
    max-height: 50px;
}

.skills .item p {
    margin-top: 10px;
}

@media only screen and (max-width: 600px) {
    .skills section .title {
        text-align: center;
    }

    .skills .highlight {
        margin: 20px auto;
    }
}