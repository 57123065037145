.nav-icon {
    z-index: 200;
    background-color: #49276B;
    position: fixed;
    top: 25px;
    right: 25px;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.nav {
    display: none;
}

.nav.show {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #DD97BD;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    z-index: 100;
}

.nav.show ul {
    padding: 0;
}

.navbar-menu {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    list-style: none;
    line-height: 2;
    cursor: pointer;
}

.navbar-menu a {
    text-decoration: none;
    color: #49276B;
}

.navbar-menu a:active,
.navbar-menu a:hover {
    font-weight: bold;
}