.education {
    background-color: #7D4B8A;
    padding: 50px;
}

.education section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.education section h3,
.education section h4,
.education section h5 {
    margin: 5px auto;
}

.education section h4 {
    color: #EEEEEE;
}