.volunteer {
    background-color: #DC96BA;
    padding: 70px;
}

.volunteer section {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.volunteer section .activity h4 {
    margin-top: 5px;
    font-weight: bold;
    color: #49276B;
    text-transform: capitalize;
}

.volunteer section .activity li {
    margin: 10px 15px;
}

.volunteer section .activity li::marker {
    margin: 10px 15px;
    content: '▶  ';
}