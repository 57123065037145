.work {
    background-color: #7D4B8A;
    padding: 50px;
}

.work section li::marker {
    content: '▸  ';
    color: #FDDAE9;
}

.work section .items ul {
    line-height: 120%;
}

.work section .items ul li {
    padding-bottom: 10px;
}