.container {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: #EEEEEE;
}

.dark {
    color: #49286B;
}

h2 {
    text-align: center;
    font-size: 40px;
    font-weight: initial;
    margin-bottom: 50px;
    text-decoration: underline;
    text-decoration-color: #FDDAE9;
    text-underline-offset: 10px;
    text-transform: uppercase;
}

h3 {
    font-size: 30px;
    font-weight: initial;
    margin-bottom: 5px;
}

h4 {
    font-size: 20px;
    font-weight: initial;
    margin: 5px 0;
    text-transform: uppercase;
    color: #FDDAE9;
}

h5 {
    font-size: 15px;
    font-weight: initial;
    margin-top: 5px;
    color: #FDDAE9;
}

section {
    display: flex;
    margin-bottom: 50px;
    padding: 20px;
}

section .title {
    width: 35%;
    text-align: center;
    border-right: 1px solid #EEEEEE;
}

section .items {
    width: 65%;
}

.highlight {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 70px auto;
}

.highlight span {
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: #7D4B8A;
    color: #EEEEEE;
}

@media only screen and (max-width: 600px) {
    section {
        display: block;
        margin-bottom: 20px;
        padding: 0;
    }

    section .title {
        width: 100%;
        text-align: left;
        border: none;
    }

    section .items {
        width: 100%;
    }
}


.bg {
    background-color: #FDDAE9;
    background-color: #DD97BD;
    background-color: #7F4B8A;
    background-color: #49276B;
}

.save {
    background-color: #231942;
    background-color: #5E548E;
    background-color: #9F86C0;
    background-color: #BE95C4;
    background-color: #E0B1CB;


    background-color: #6247AA;
    background-color: #7251B5;
    background-color: #815AC0;
    background-color: #9163CB;
    background-color: #A06CD5;
    background-color: #B185DB;
    background-color: #C19EE0;
    background-color: #D2B7E5;
    background-color: #DAC3E8;
    background-color: #DEC9E9;

    background-color: #10002B;
    background-color: #240046;
    background-color: #3C096C;
    background-color: #5A189A;
    background-color: #7B2CBF;
    background-color: #9D4EDD;
    background-color: #C77DFF;
    background-color: #E0AAFF;
}